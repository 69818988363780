/* eslint-disable react/jsx-props-no-spreading */
// import { Howl } from 'howler';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { animatedState } from './computeState';

const propTypes = {
    addPoints: PropTypes.func.isRequired,
    // removePoints: PropTypes.func.isRequired,
    state: PropTypes.shape({
        happiness: PropTypes.number,
        hasLove: PropTypes.bool,
        isCrying: PropTypes.bool,
        isLaughing: PropTypes.bool,
        hasMusic: PropTypes.bool,
        isEating: PropTypes.bool,
        hasCheese: PropTypes.bool,
        isSleeping: PropTypes.bool,
        hasShit: PropTypes.bool,
        isHungry: PropTypes.bool,
        isTired: PropTypes.bool,
        isAngry: PropTypes.bool,
    }).isRequired,
    paused: PropTypes.bool,
};

const defaultProps = {
    paused: false,
};

function KidPoints({ addPoints, state, paused }) {
    const {
        // happiness = 0,
        // hasLove = false,
        // isCrying = false,
        // hasMusic = false,
        // isLaughing = false,
        // isEating = false,
        // hasCheese = false,
        // isSleeping = false,
        // hasShit = false,
        // isHungry = false,
        // isTired = false,
        // isAngry = false,
        isSleeping = false,
    } = state;

    // const pointsToAddRef = useRef(0);
    // pointsToAddRef.current = happiness * 30;

    // if (isLaughing) {
    //     pointsToAddRef.current += 10;
    // }
    // if (isSleeping) {
    //     pointsToAddRef.current += 40;
    // }
    // if (hasLove) {
    //     pointsToAddRef.current += 10;
    // }
    // if (isEating) {
    //     pointsToAddRef.current += 10;
    // }
    // if (hasMusic) {
    //     pointsToAddRef.current += 10;
    // }
    // if (isAngry) {
    //     pointsToAddRef.current -= 40;
    // }
    // if (isCrying) {
    //     pointsToAddRef.current -= 20;
    // }
    // if (isHungry) {
    //     pointsToAddRef.current -= 20;
    // }
    // if (isTired) {
    //     pointsToAddRef.current -= 10;
    // }
    // if (hasShit) {
    //     pointsToAddRef.current -= 20;
    // }
    // if (hasCheese) {
    //     pointsToAddRef.current -= 30;
    // }

    // useEffect(() => {
    //     if (paused) {
    //         return () => {};
    //     }
    //     let lastTime = new Date().getTime();
    //     const interval = setInterval(() => {
    //         const now = new Date().getTime();
    //         const elapsed = now - lastTime;
    //         const points = (elapsed / 1000) * pointsToAddRef.current;
    //         lastTime = now;
    //         addPoints(points);
    //     }, 100);
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [paused, addPoints]);

    useEffect(() => {
        if (paused) {
            return () => {};
        }
        if (isSleeping) {
            addPoints(1);
        }
        return () => {};
    }, [isSleeping]);

    return null;
}

KidPoints.propTypes = propTypes;
KidPoints.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default animatedState(KidPoints);
