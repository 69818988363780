/* eslint-disable react/jsx-props-no-spreading */
import { Howl } from 'howler';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import { animatedState } from './computeState';

const propTypes = {
    audio: PropTypes.instanceOf(Howl).isRequired,
    paused: PropTypes.bool,
    state: PropTypes.shape({
        isCrying: PropTypes.bool,
        isLaughing: PropTypes.bool,
        hasMusic: PropTypes.bool,
        isEating: PropTypes.bool,
        hasCheese: PropTypes.bool,
        hasDiaper: PropTypes.bool,
    }).isRequired,
};

const defaultProps = {
    paused: false,
};

function KidAudio({ audio, paused, state }) {
    const {
        isCrying = false,
        hasMusic = false,
        isLaughing = false,
        isEating = false,
        hasCheese = false,
        hasDiaper = false,
    } = state;
    const soundRef = useRef({});
    const playSound = useCallback(
        (sprite) => {
            if (paused && sprite.indexOf('rire') === -1) {
                return;
            }
            soundRef.current[sprite] = audio.play(sprite);
        },
        [audio, paused],
    );
    const stopSound = useCallback(
        (sprite) => {
            const sound = soundRef.current[sprite] || null;
            if (sound !== null) {
                audio.stop(sound);
                soundRef.current[sprite] = null;
            }
        },
        [audio],
    );
    useEffect(() => {
        if (isCrying) {
            playSound('pleur_loop');
        } else {
            stopSound('pleur_loop');
        }
    }, [isCrying, playSound, stopSound]);
    useEffect(() => {
        if (hasMusic) {
            playSound('musique');
        } else {
            stopSound('musique');
        }
    }, [hasMusic, playSound, stopSound]);
    useEffect(() => {
        if (isLaughing) {
            playSound(`rire${Math.ceil(Math.random() * 5)}`);
        }
    }, [isLaughing, playSound, stopSound]);
    useEffect(() => {
        if (isEating) {
            playSound('boire');
        }
        if (hasCheese) {
            playSound('fromage');
        }
        if (hasDiaper) {
            playSound('diaper');
        }
    }, [isEating, hasCheese, hasDiaper, playSound, stopSound]);
    return null;
}

KidAudio.propTypes = propTypes;
KidAudio.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default animatedState(KidAudio);
